<template>
  <div>
    <!-- <div v-if="isVoucherCodeVerified">
      <StorePage_Home :isFromVoucher="true" :voucherInfo="voucherResponse" />
    </div> -->
    <div>
      <!-- Navbar-->
      <header class="header">
        <StorePageHeader></StorePageHeader>
      </header>

      <div class="container">
        <div class="align-items-center justify-content-center row py-5 mt-4">
          <!-- For Left Half -->
          <div class="col-md-5 pr-lg-5 mb-5 mb-md-0">
            <PageHeading
              id="pg_heading"
              :imgsrc="getImgURL()"
              heading="Redeem your Moonfrog Voucher"
              :description="
                isVoucherDirectRedeemable
                  ? this.mintrouteRedeemInstruction
                  : this.qwikcilverRedeemInstruction
              "
            />
            <p class="font-italic" style="color: yellowgreen">
              In case of queries, contact: customer.service@moonfroglabs.com
            </p>
          </div>

          <!-- Registration Form -->

          <div class="col-md-7 col-lg-6 ml-auto">
            <div>
              <b-dropdown
                id="dropdown-left"
                :text="SelectVoucherText()"
                variant="primary"
                class="m-2"
              >
                <b-dropdown-item disabled value=""
                  >Select voucher partner</b-dropdown-item
                >
                <b-dropdown-item
                  v-for="partner in giftVoucherPartners"
                  :key="partner.name"
                  :id="partner.id"
                  :value="partner.id"
                  @click="onVoucherSelected($event)"
                >
                  {{ partner.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div>
              <form id="redeem-form" action="#" v-if="selectedVoucherId !== ''">
                <!-- Voucher Source -->

                <!-- Player ID -->
                <div v-if="isVoucherDirectRedeemable">
                  <div
                    class="voucherCode"
                    id="selectGames"
                    v-if="this.selectedVoucherId == 'stellr'"
                  >
                    <b-dropdown
                      id="selectGames"
                      :text="SelectGameText()"
                      variant="primary"
                      class="m-2"
                      style="
                        max-width: 300px;
                        border: solid;
                        text-align: center;
                      "
                    >
                      <b-dropdown-item disabled value=""
                        >Select a Game</b-dropdown-item
                      >
                      <div>
                        <b-dropdown-item
                          v-for="game in games"
                          :key="game.name"
                          :id="game.gameId"
                          :value="game.name"
                          @click="onGameSelected($event)"
                          :active="selectedGameId == id"
                        >
                          <span class="icon">
                            <img
                              :src="game.icon"
                              class="image"
                              width="30px"
                              height="30px"
                            />
                          </span>
                          {{ game.name }}
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                  <div class="input-group voucherCode">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text bg-white px-4 border-md border-right-0 playerId"
                      >
                        <v-icon>mdi-account</v-icon>
                      </span>
                    </div>
                    <input
                      v-model="playerId"
                      @paste.prevent
                      id="playerId"
                      type="text"
                      placeholder="Player ID"
                      class="form-control bg-white border-left-0 border-md playerId"
                    />
                  </div>
                  <!-- Confirm Player ID -->
                  <div class="input-group voucherCode">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text bg-white px-4 border-md border-right-0 playerId2"
                      >
                        <v-icon>mdi-account-check</v-icon>
                      </span>
                    </div>
                    <input
                      v-model="confirmPlayerId"
                      id="playerId2"
                      type="text"
                      placeholder="Confirm Player ID"
                      class="form-control bg-white border-left-0 border-md playerId2"
                      @paste.prevent
                    />
                  </div>
                </div>

                <!-- Voucher Code -->
                <div>
                  <div class="input-group voucherCode">
                    <v-icon
                      class="input-group-text input-group-prepend bg-white px-4 border-md border-right-0"
                      >mdi-ticket</v-icon
                    >

                    <input
                      v-model="voucherCode"
                      id="voucherCode"
                      type="text"
                      v-if="selectedVoucherId !== 'none'"
                      :placeholder="getVoucherCodeText()"
                      class="form-control bg-white border-left-0 border-md"
                    />
                  </div>
                  <div class="input-group voucherCode">
                    <span
                      v-if="!isVoucherDirectRedeemable"
                      class="input-group-text input-group-prepend bg-white px-4 border-md border-right-0"
                    >
                      <v-icon>mdi-ticket</v-icon>
                    </span>
                    <input
                      v-if="!isVoucherDirectRedeemable"
                      v-model="voucherPin"
                      id="voucherPin"
                      type="text"
                      :placeholder="
                        this.isVoucherDirectRedeemable
                          ? 'Voucher Pin'
                          : 'Gift Card Pin'
                      "
                      class="form-control bg-white border-left-0 border-md"
                    />
                  </div>
                </div>
                <!-- Submit Button -->
                <ActionButton
                  id="submit-btn"
                  text="Redeem Voucher"
                  @onclick="submit"
                />
                <!-- Error Text -->
                <div class="col-lg-12" id="errText" style="margin-top: 10px">
                  <p style="color: red">{{ errorText }}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer style="text-align: center; color: #6c757d !important">
        <p>
          By using the website, you are agreeing to Moonfrog Labs
          <router-link
            class="footer-link"
            :to="{ name: 'redemptionTnC' }"
            target="_blank"
          >
            Terms &amp; Conditions
          </router-link>
        </p>
      </footer>

      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import ActionButton from "@/components/ActionButton";
import * as client from "@/client/webclient";
import * as SageConstants from "../config/SageConstants"
import StorePage_Home from "./StorePage_Home.vue";
import * as StoreWebClient from "../client/StoreWebClient";
import StorePageHeader from "../components/StorePageHeader"
import * as SageUtils from "@/utils/SageUtils";
export default {
  name: "Redeem",
  props: {},
  components: {
    PageHeading,
    ActionButton,
    // StorePage_Home,
    StorePageHeader
  },

  async created() {
    await this.init()
  },

  data() {
    return {
      isLoading: false,

      playerId: "",
      confirmPlayerId: "",
      voucherCode: "",
      voucherPin: "",
      isVoucherDirectRedeemable: true,
      selectedCountry: "",
      giftVoucherPartners: [],
      isVoucherCodeVerified: false,
      errorText: "",
      voucherResponse: {},
      selectedVoucherId: "",
      qwikcilverRedeemInstruction: SageConstants.QWIKCILVER_REDEEM_INSTRUCTIONS,
      mintrouteRedeemInstruction: SageConstants.MINTROUTE_REDEEM_INSTRUCTIONS,
      games: {},
      selectedGameId: undefined
    };
  },
  methods: {
    getVoucherCodeText(){
      switch(this.selectedVoucherId){
        case SageConstants.MINTROUTE:
        case SageConstants.STELLR:
          return 'Voucher Code'
        case SageConstants.QWIKCILVER:
          return 'Gift Card No'
        case SageConstants.EZPIN:
          return 'Voucher Pin'
      }
    },
    async init(){
      this.isLoading = true
      let res = await StoreWebClient.getStoreConfig()
      SageConstants.GiftVoucherPartners.forEach((vp)=>{
        if(res.config.vouchers[vp.id].display){
          this.giftVoucherPartners.push(vp)
        }
      })
      this.isLoading = false
    },
    SelectGameText() {
      let game
      game = this.games.find((game) => {
        // console.log("game" + JSON.stringify(game))
        return game.gameId == this.selectedGameId
      })
      return game ? game.name : '---- Select Game ----'
    },
    SelectVoucherText() {
      let partner
      partner = SageConstants.GiftVoucherPartners.find((element) => {
        // console.log(element)
        return element.id == this.selectedVoucherId
      })

      return partner ? partner.name : '---- Select Voucher Source ----'
    },
    onCountrySelected: function (event) {
      this.selectedCountry = event.target.value
    },
    onGameSelected(event) {
      // console.log("selectedGameId " + event.target.id)
      this.selectedGameId = event.target.id

      //console.log("selectedVoucherId: ", JSON.stringify(this.selectedGameId));
    },
    async onVoucherSelected(event) {
      // console.log("selectedVoucherId: ", event.target.id)
      this.selectedVoucherId = event.target.id
      // console.log("selectedVoucherId: ", event.target.value);
      // console.log("Vouchers : ", this.giftVoucherPartners);
      if (this.selectedVoucherId === SageConstants.MINTROUTE || this.selectedVoucherId === SageConstants.EZPIN) {
        // console.log("voucher selectedVoucherId  " + this.selectedVoucherId)
        this.isVoucherDirectRedeemable = true;
        //this.$router.push('/redeem/mintroute')
      } else if (this.selectedVoucherId === SageConstants.QWIKCILVER) {
        this.isVoucherDirectRedeemable = false;
        // this.$router.push('/qwikcilver')
      }
      else if (this.selectedVoucherId === SageConstants.STELLR) {
        this.isLoading = true
        this.games = await StoreWebClient.getGames("voucher")
        this.isLoading = false
        // console.log("Games : ", JSON.stringify(this.games))
        this.isVoucherDirectRedeemable = true;
      } else {
        this.selectedVoucherId = ""
      }
      this.playerId = "";
      this.confirmPlayerId = "";
      this.voucherCode = "";
      this.voucherPin = "";
      this.selectedGameId = ""

    },
    async submit() {
      this.voucherCode = this.voucherCode.trim();
      this.voucherPin = this.voucherPin.trim()
      let err = false;
      this.errorText = "";
      if (this.isVoucherDirectRedeemable == true) {
        this.playerId = this.playerId.trim();
        this.confirmPlayerId = this.confirmPlayerId.trim();
        this.playerId !== this.confirmPlayerId ?
          (this.showWarningBorder("playerId"),
            this.showWarningBorder("playerId2"),
            (this.errorText = "Player IDs entered do not match!"),
            (err = true)) :
          (this.showNormalBorder("playerId"),
            this.showNormalBorder("playerId2"));
        this.playerId.length === 0 ?
          (this.showWarningBorder("playerId"),
            (this.errorText = "Player ID cannot be empty!"),
            (err = true)) :
          this.showNormalBorder("playerId");
      }
      this.selectedVoucherId === SageConstants.STELLR && this.selectedGameId === "" ?
        (this.showWarningBorder("selectGames"),
          (this.errorText = "Please select a game"),
          (err = true)) :
        this.showNormalBorder("selectGames");

      this.voucherCode.length === 0 ?
        (this.showWarningBorder("voucherCode"),
          (this.errorText = "Voucher Code cannot be empty!"),
          (err = true)) :
        this.showNormalBorder("voucherCode");
      this.selectedVoucherId === "" ?
        (this.showWarningBorder("voucherSource"),
          (this.errorText = "Voucher Type cannot be empty!"),
          (err = true)) :
        this.showNormalBorder("voucherSource");

      !this.isVoucherDirectRedeemable && this.voucherPin.length === 0 ?
        (this.showWarningBorder("voucherPin"),
          (this.errorText = "Voucher Pin cannot be empty!"),
          (err = true)) :
        this.showNormalBorder("voucherPin");

      if (err) return;

      try {
        this.isLoading = true;
        /**
         *  response: {
         *      playerName: string,
         *      gameName: string,
         *      packageName: string,
         *      voucherCode: string,
         *      voucherId: string,
         *      voucherSerial: string,
         *  }
         */
        if (this.isVoucherDirectRedeemable) //mintRoute
        {
          await this.redeemDirect()
        } else {
          await this.launchStorePage(this.selectedVoucherId)

        }
      } catch (errr) {
        this.showConnectionErrorDialog(errr);

      } finally {
        this.isLoading = false;
        this.playerId = "";
        this.confirmPlayerId = "";
        this.voucherCode = "";
        this.voucherPin = "";
        this.selectedVoucherId = "";
        this.selectedGameId = ""
      }

    },
    async launchStorePage(voucherId) {
      //this.$router.push("/store")
      // console.log("Voucher: " + this.voucherCode + " " + this.selectedVoucherId)
      try {
        let response = await StoreWebClient.checkGiftCardBalance(
          this.voucherCode,
          this.selectedVoucherId,
          this.voucherPin
        );
        this.voucherResponse = response;
        this.voucherResponse["voucherPin"] = this.voucherPin
        // console.log("VoucherResponse: " + JSON.stringify(this.voucherResponse))
        this.$router.push({name : "Store",params:
        {
          voucherInfo: this.voucherResponse
        }})
      } catch (err) {
        this.$swal.fire({
          icon: "error",
          text: err ? err : "asfsdf",
        });
      }
    },
    async redeemDirect(voucherId) {
      let payload = {
        gameId: this.selectedGameId,
        targetPid: this.playerId,
        checkoutType: "Gift_Card_Checkout",
        giftCardInfo: {
          voucherSource: this.selectedVoucherId,
          voucherCode: this.voucherCode
        }
      }
      // console.log("Payload: " + JSON.stringify(payload))
      this.isLoading = true
      let response = await StoreWebClient.createGiftCardCheckout(
        payload
      );
      this.isLoading = false
      let that = this
      this.$swal
        .fire({
          icon: SageUtils.getIconFromResponse(response.status),
          title: SageUtils.getTitleFromResponse(response.status),
          html: SageUtils.getSuccessTextFromRedeemResponse(response, this.playerId),
          confirmButtonText: "Print",
        })
        .then((result) => {
          that.isLoading = false;
          that.playerId = "";
          that.confirmPlayerId = "";
          that.voucherCode = "";
          that.voucherPin = "";
          that.selectedVoucherId = "";
          that.selectedGameId = ""
          if (result.isConfirmed) {
            SageUtils.printMessage();

          }
        });

    },




    getImgURL() {
      return require("../assets/Frog_Voucher_F.png");
    },

    showWarningBorder(id) {
      let elements = document.getElementsByClassName(id);
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("border-md");
        elements[i].classList.add("border-danger");
      }
    },
    showNormalBorder(id) {
      let elements = document.getElementsByClassName(id);
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("border-danger");
        elements[i].classList.add("border-md");
      }
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.go()
        }
      });
    },
  },
};
</script>

<style scoped>
#submit-btn {
  color: #fff;
}

.bg {
  background: white;
  min-height: 100vh;
  max-width: none;
}

.bg-image {
  background-position: right bottom;
  background-repeat: no-repeat;
  background-image: url("../assets/Frogie_Outline.jpg");
  background-size: contain;
}

.footer-link {
  text-align: center;
  font-weight: bold;
  color: #007bff;
}

/* .voucherPin {
    margin: 0 10px;
    padding: 1px;
} */

.voucherCode {
  margin-bottom: 10px;
  padding: 0 0 0 0;
}

.footer-link:hover {
  text-decoration: underline;
}
</style>